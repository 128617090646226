<template>
  <v-dialog v-model="editDialog" max-width="1000" scrollable persistent>
    <v-card>
      <v-card-title>edit: {{ myData.title }}</v-card-title>
      <v-card-text style="padding: 16px">
        <v-text-field
          outlined
          label="Title"
          v-model="create.title"
          maxLength="60"
        ></v-text-field>
        <vue-editor
          :editor-toolbar="customToolbar"
          v-model="create.details"
          placeholder="Put your details here"
        ></vue-editor>

        <v-text-field
          style="margin-top: 20px"
          v-model="create.tags"
          dense
          label="tags"
          placeholder="Comma separated words that describes this blog"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-switch
          v-model="create.publish"
          :label="`Publish: ${create.publish}`"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  props: {
    editDialog: Boolean,
    myId: { type: Number },
    myData: { type: Object },
  },
  components: {
    VueEditor,
  },
  mixins: [validationMixin, Utils],
  data() {
    return {
      // https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/default-toolbar.js
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      create: {
        title: this.myData.title,
        details: this.myData.details,
        publish: this.myData.publish,
        tags: this.myData.tags,
      },
    };
  },
  validations: {
    create: {
      title: { required, maxLength: maxLength(60) },
      details: { required },
    },
  },
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let payload = {
        id: this.myId,
        data: this.create,
        state: false,
      };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
