<template>
  <div class="profile">
    <v-card :loading="loading" loader-height="1">
      <v-toolbar dense flat>
        <v-btn color="primary" text x-small @click="refresh">
          refresh data
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-tabs v-model="ptabs" centered>
              <v-tab key="my_picture">personal</v-tab>
              <v-tab key="pro_picture">profile</v-tab>
            </v-tabs>
            <v-tabs-items v-model="ptabs">
              <v-tab-item key="my_picture">
                <v-card flat outlined>
                  <v-img :src="userPix()" contain min-height="200"></v-img>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      color="primary"
                      small
                      text
                      @click.stop="pixDialog = true"
                    >
                      Update Picture
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <UpdatePicture
                  :pixDialog="pixDialog"
                  @closePix="closePix"
                  @submitPix="submitPix"
                />
              </v-tab-item>
              <v-tab-item key="pro_picture">
                <v-card flat outlined>
                  <v-img :src="proPix()" contain min-height="200"></v-img>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      color="primary"
                      small
                      text
                      @click.stop="pictureDialog = true"
                    >
                      Update Picture
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <UpdateProPicture
                  :pictureDialog="pictureDialog"
                  @closePicture="closePicture"
                  @submitPicture="submitPicture"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col cols="12" sm="9">
            <v-tabs v-model="tabs" centered>
              <v-tab key="profile">profile</v-tab>
              <v-tab key="personal">personal</v-tab>
              <v-tab key="probody">professional body</v-tab>
              <v-tab key="business">business</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item key="profile">
                <v-card flat outlined v-resize="onResize">
                  <v-card-text>
                    <v-tabs v-model="proTab" centered>
                      <v-tab key="mybrief">brief</v-tab>
                      <v-tab key="mydetails">details</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="proTab">
                      <v-tab-item key="mybrief" style="padding-top: 20px">
                        {{ profilia.brief }}
                      </v-tab-item>
                      <v-tab-item key="mydetails" style="padding-top: 20px">
                        <div class="scrollable" :style="{ height: panheight }">
                          <div v-html="profilia.details"></div>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      color="primary"
                      small
                      text
                      @click.stop="profileDialog = true"
                    >
                      update profile
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <MyProfile
                  :profileDialog="profileDialog"
                  @closeProfile="closeProfile"
                  @submitProfile="submitProfile"
                />
              </v-tab-item>
              <v-tab-item key="personal">
                <v-card flat outlined>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>
                            <ul>
                              <li>Name:</li>
                              <li>
                                {{ myself.salutations | capitalize }}
                                {{ myself.first_name }}
                                {{ myself.mid_name | capitalize }}
                                {{ myself.last_name | capitalize }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>Gender:</li>
                              <li>{{ myself.gendersName }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>Marital:</li>
                              <li>{{ myself.marital_status_name }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>Birth Date:</li>
                              <li>{{ myself.date_of_birth | myDate }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>contact:</li>
                              <li>{{ myself.mobile_phone }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>last Updated:</li>
                              <li>{{ myself.updated | myDating }}</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <div style="margin-bottom: 5px"></div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      color="primary"
                      small
                      text
                      @click.stop="personalDialog = true"
                    >
                      update personal data
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <UpdatePersonal
                  :personalDialog="personalDialog"
                  @closePersonal="closePersonal"
                  @submitPersonal="submitPersonal"
                />
              </v-tab-item>
              <v-tab-item key="probody">
                <v-card flat outlined v-resize="onResize">
                  <v-card-text>
                    <div class="mylist" style="margin: 15px 0 0 0">
                      <ul>
                        <li>
                          <div class="myleft">professional license name</div>
                          <div class="myright">
                            {{ details.professional_licenses | capitalize }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">registration number</div>
                          <div class="myright">
                            {{ details.registration_number }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">expiry date</div>
                          <div class="myright">
                            {{ details.profession_expiry | myDate }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      block
                      @click="professionDialog = true"
                      >update</v-btn
                    >
                    <Professional
                      :professionDialog="professionDialog"
                      :licenseId="details.professional_licensesid"
                      :license="details.professional_licenses"
                      :register="details.registration_number"
                      :expiry="details.profession_expiry"
                      @closeProf="closeProf"
                      @submitProf="submitProf"
                    />
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item key="business">
                <v-card outlined>
                  <v-card-text>
                    <v-simple-table>
                      <tbody class="business">
                        <tr>
                          <td>
                            <ul>
                              <li>Business Name:</li>
                              <li>
                                {{ details.business_name }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>Business Email:</li>
                              <li>{{ details.business_email }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>phone:</li>
                              <li>{{ details.phone }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>Country:</li>
                              <li>{{ details.country }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>region/state:</li>
                              <li>{{ details.regions }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>district:</li>
                              <li>{{ details.districts }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>physical location:</li>
                              <li>{{ details.physical_address }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>city:</li>
                              <li>{{ details.city }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>postal address:</li>
                              <li>{{ details.postal_address }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>postal address:</li>
                              <li>{{ details.postal_address }}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>geo location:</li>
                              <li>
                                {{ details.geolocation }}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn color="primary" icon v-on="on">
                                      <v-icon>mdi-earth</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View on Google Map</span>
                                </v-tooltip>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>rating:</li>
                              <li>
                                <v-rating
                                  :value="details.ratings"
                                  background-color="#c8cdd7"
                                  color="success"
                                  hover
                                  length="5"
                                  readonly
                                ></v-rating>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>date created:</li>
                              <li>{{ details.created | myDating }}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>last updated:</li>
                              <li>{{ details.updated | myDating }}</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      color="primary"
                      small
                      text
                      @click.stop="bizDialog = true"
                    >
                      edit business profile
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <UpdateBiz
                  :bizDialog="bizDialog"
                  @closeBiz="closeBiz"
                  @submitBiz="submitBiz"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import UpdatePicture from "../../components/accounts/picture.vue";
import UpdateProPicture from "../../components/accounts/profile_picture.vue";
import UpdatePersonal from "../../components/accounts/personal.vue";
import UpdateBiz from "../../components/accounts/profile_business.vue";
import MyProfile from "../../components/accounts/profile.vue";
import Professional from "../../components/accounts/mysub/professionalDialog.vue";

export default {
  components: {
    MyProfile,
    UpdatePersonal,
    UpdatePicture,
    UpdateProPicture,
    UpdateBiz,
    Professional,
  },
  mixins: [Utils],
  data: () => ({
    tabs: null,
    ptabs: null,
    proTab: null,
    pixDialog: false,
    pictureDialog: false,
    bizDialog: false,
    personalDialog: false,
    profileDialog: false,
    loading: false,
    professionDialog: false,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    myself: {},
    details: {},
    profilia: {},
    pix: require("../../assets/images/my_user_black.png"),
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 450 + "px";
      return h;
    },
  },
  created() {
    this.find();
    this.detail();
  },
  methods: {
    refresh() {
      this.find();
      this.detail();
    },
    detail() {
      this.loading = true;
      let self = this;
      Restful.subscribers
        .detail()
        .then((response) => {
          console.log("465", response.data);
          this.details = response.data;
          this.profilia = response.data.profile;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .find()
        .then((response) => {
          console.log(response.data);
          this.myself = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    userPix() {
      let p = this.myself.my_pix;
      if (p === "") {
        return this.pix;
      } else {
        return p;
      }
    },
    proPix() {
      let p = this.myself.profile_picture;
      if (p === "") {
        return this.pix;
      } else {
        return p;
      }
    },
    closePix(p) {
      console.log(p);
      this.pixDialog = p.state;
    },
    submitPix(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.accounts
        .myPicture(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          localStorage.setItem("user.pix", p.data.picture);
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.pixDialog = p.state;
    },
    closePersonal(p) {
      console.log(p);
      this.personalDialog = p.state;
    },
    submitPersonal(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .updateProfile(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.personalDialog = p.state;
    },
    closeBiz(p) {
      console.log(p);
      this.bizDialog = p.state;
    },
    submitBiz(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers
        .update(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.bizDialog = p.state;
    },
    closeProfile(p) {
      console.log(p);
      this.profileDialog = p.state;
    },
    submitProfile(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers
        .profileUpdate(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.detail();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.profileDialog = p.state;
    },
    closeSchedule(p) {
      this.scheduleDialog = p.state;
    },
    submitSchedule(p) {
      console.log(p.data);
      this.scheduleDialog = p.state;
    },
    closePicture(p) {
      console.log(p);
      this.pictureDialog = p.state;
    },
    submitPicture(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.accounts
        .profilePicture(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.pictureDialog = p.state;
    },
    closeProf(p) {
      this.professionDialog = p.state;
    },
    submitProf(p) {
      console.log("prof:", p.data);

      let self = this;
      Restful.accounts.profession
        .update(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find();
          this.detail();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.professionDialog = p.state;
    },
  },
};
</script>
