<template>
  <v-dialog v-model="newDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new certification</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field dense label="name" v-model="view.name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="registration Number"
              v-model="view.registrationNumber"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="document Type"
              :items="doctypeList"
              item-value="id"
              item-text="name"
              v-model="view.documentTypeId"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="view.aquiredSince"
                  label="Date Started"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                v-model="view.aquiredSince"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-text-field
          dense
          label="description"
          v-model="view.description"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      doctypeList: [],
      menu1: false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      view: {
        name: null,
        registrationNumber: null,
        description: null,
        documentTypeId: null,
        aquiredSince: null,
      },
    };
  },
  validations: {
    view: {
      name: {
        required,
      },
      registrationNumber: {
        required,
      },
      description: {
        required,
      },
      documentTypeId: {
        required,
      },
      aquiredSince: {
        required,
      },
    },
  },
  created() {
    this.doctype();
  },
  watch: {},
  methods: {
    doctype() {
      this.loading = true;
      let self = this;
      Restful.general.docType
        .list()
        .then((response) => {
          console.log(response.data);
          this.doctypeList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let name = this.view.name;
      let registrationNumber = this.view.registrationNumber;
      let description = this.view.description;
      let documentTypeId = this.view.documentTypeId;
      let aquiredSince = Date.parse(this.view.aquiredSince);
      let data = {
        name,
        registrationNumber,
        description,
        documentTypeId,
        aquiredSince,
      };
      console.log("data:", data);
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
