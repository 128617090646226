<template>
  <v-dialog v-model="personalDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>Update Profile</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.gendersid"
                :items="genderlist"
                item-text="name"
                item-value="id"
                label="gender"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.salutationsid"
                :items="salutlist"
                item-text="name"
                item-value="id"
                label="salutation"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="create.first_name"

                label="first name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="create.midName"

                label="mid name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="create.last_name"

                label="last name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.married_statusid"
                :items="marriagelist"

                item-text="name"
                item-value="id"
                label="marriage status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- <v-select
              dense
              label="date_of_birth"
              v-model="create.date_of_birth"
            ></v-select> -->
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="create.date_of_birth"
                    label="Date of Birth"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="create.date_of_birth"
                  :max="today"
                  @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-text-field
            v-model="create.mobile_phone"
            label="mobile phone"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.create.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import moment from "moment";
import {decimal, maxLength, minLength, required,} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    personalDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      datalist: [],
      loading: false,
      menu2: false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      create: {
        first_name: null,
        midName: null,
        last_name: null,
        salutationsid: null,
        gendersid: null,
        married_statusid: null,
        date_of_birth: null,
        mobile_phone: null,
      },
      genderlist: [],
      salutlist: [],
      marriagelist: [],
    };
  },
  validations: {
    create: {
      salutationsid: {
        required,
      },
      gendersid: {
        required,
      },
      married_statusid: {
        required,
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      date_of_birth: {
        required,
      },
      mobile_phone: {
        required,
        decimal,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  created() {
    this.find();
    this.gender();
    this.salut();
    this.marriage();
  },
  methods: {
    gender() {
      this.loading = true;
      let self = this;
      Restful.general.gender
          .list()
          .then((response) => {
            console.log(response.data);
            this.genderlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    salut() {
      this.loading = true;
      let self = this;
      Restful.general.salutations
          .list()
          .then((response) => {
            console.log(response.data);
            this.salutlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    marriage() {
      this.loading = true;
      let self = this;
      Restful.general.marriage
          .list()
          .then((response) => {
            console.log(response.data);
            this.marriagelist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
          .find()
          .then((response) => {
            console.log(response.data);
            this.create.first_name = response.data.first_name;
            this.create.midName = response.data.mid_name;
            this.create.last_name = response.data.last_name;
            this.create.salutationsid = response.data.salutationsId;
            this.create.gendersid = response.data.gendersid;
            this.create.married_statusid = response.data.marital_status_id;
            this.create.date_of_birth = moment(
                response.data.date_of_birth
            ).format("yyyy-MM-DD");
            this.create.mobile_phone = response.data.mobile_phone;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {state: false};
      this.$emit("closePersonal", payload);
    },
    submit() {
      let salutationsid = this.create.salutationsid;
      let gendersid = this.create.gendersid;
      let married_statusid = this.create.married_statusid;
      let first_name = this.create.first_name;
      let midName = this.create.midName;
      let last_name = this.create.last_name;
      let date_of_birth = Date.parse(this.create.date_of_birth);
      let mobile_phone = this.create.mobile_phone;
      let data = {
        salutationsid,
        gendersid,
        married_statusid,
        first_name,
        midName,
        last_name,
        date_of_birth,
        mobile_phone,
      };
      console.log(data);

      let payload = {state: false, data: data};
      this.$emit("submitPersonal", payload);
    },
  },
};
</script>
