<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-tabs grow centered icons-and-text v-model="tab" show-arrows>
        <v-tab key="profile">
          Profile
          <v-icon>mdi-account-edit</v-icon>
        </v-tab>
        <v-tab key="subscriptions">
          Subscriptions
          <v-icon>mdi-flag-outline</v-icon>
        </v-tab>
        <v-tab v-show="isMedics" key="profession">
          Background
          <v-icon>mdi-briefcase-outline</v-icon>
        </v-tab>
        <v-tab v-show="isMedics" key="certify">
          Certifications
          <v-icon>mdi-briefcase-outline</v-icon>
        </v-tab>
        <v-tab key="health_blogs">
          my health blogs
          <v-icon>mdi-briefcase-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="myaccount">
        <v-tab-item key="profile">
          <MyProfile />
        </v-tab-item>
        <v-tab-item key="subscriptions">
          <MySubscriptions />
        </v-tab-item>
        <v-tab-item v-show="isMedics" key="profession">
          <v-card>
            <v-card-text>
              <MyBackground />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-show="isMedics" key="certify">
          <v-card>
            <v-card-text>
              <Certificates />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="health_blogs">
          <v-card>
            <v-card-text>
              <MicroBlogs />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import MyProfile from "../../views/backgrounds/profile.vue";
import MySubscriptions from "../../views/backgrounds/subscriptions.vue";
import MyBackground from "../../views/backgrounds/subscriber_background.vue";
import Certificates from "../../views/backgrounds/certify.vue";
import MicroBlogs from "../../views/backgrounds/microBlogs.vue";

import Util from "../../mixins/utils";
export default {
  components: {
    MyProfile,
    MySubscriptions,
    MyBackground,
    Certificates,
    MicroBlogs,
  },
  mixins: [WinScroll("position"), Util],
  data() {
    return {
      tab: null,
      crumbs: [
        {
          exact: true,
          link: true,
          text: this.capitalize(localStorage.getItem("user.name")),
        },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    isMedics: function () {
      let subtype = localStorage.getItem("user.subscriber_type");
      if (subtype > 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  methods: {
    mySubscription(event) {
      console.log("data after child handle: ", event);
    },
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
