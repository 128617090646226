<template>
  <v-dialog v-model="professionDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>Professional body update</v-card-title>
      <v-card-text style="margin: 20px 0 0 0">
        <!-- {{ licenseId }} => {{ license }} => {{ register }} => {{ expiry }} -->

        <v-text-field
          dense
          label="name"
          v-model="view.registration_number"
        ></v-text-field>

        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="view.profession_expiry"
              label="Date Started"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="today"
            v-model="view.profession_expiry"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  mixins: [validationMixin],
  props: {
    professionDialog: Boolean,
    licenseId: { type: Number },
    license: { type: String },
    register: { type: String },
    expiry: { type: Number },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      doctypeList: [],
      menu1: false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      view: {
        registration_number: null,
        profession_expiry: null,
      },
    };
  },
  validations: {
    view: {
      registration_number: {
        required,
      },
      profession_expiry: {
        required,
      },
    },
  },
  created() {
    this.view.registration_number = this.register;
    this.view.profession_expiry = moment(this.expiry).format("yyyy-MM-DD");
  },
  watch: {},
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeProf", payload);
    },
    submit() {
      let registration_number = this.view.registration_number;
      let profession_expiry = Date.parse(this.view.profession_expiry);

      let data = {
        registration_number,
        profession_expiry,
      };
      let payload = { state: false, data: data };
      console.log("payload:", payload);

      this.$emit("submitProf", payload);
    },
  },
};
</script>
