<template>
  <v-dialog v-model="pictureDialog" persistent max-width="700">
    <v-card>
      <v-card-title>Profile picture</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="10">
            <v-file-input
              label="Upload your picture here"
              clearable
              show-size
              truncate-length="15"
              accept="image/png, image/jpeg"
              @change="changePix"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="2">
            <v-avatar size="65" color="#cfe0f8">
              <v-img :src="myPicture"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        {{ myPix }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="disabled"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
export default {
  mixins: [Utils],
  props: {
    pictureDialog: Boolean,
  },
  data() {
    return {
      disabled: true,
      myPix: null,
      pix: 0,
      myPicture: null,
    };
  },
  watch: {
    pix: function (a) {
      if (a > 5000000) {
        this.myPix = "Picture has to be less than 50KB (0.05MB) in size";
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
  methods: {
    changePix(e) {
      if (e != null) {
        console.log("e:", e);
        console.log("size:", e.size);
        this.pix = e.size;
        this.getPix(e).then((response) => {
          let pix = response;
          this.myPicture = pix;
          console.log(this.myPicture);
        });
      }
    },

    closeDialog() {
      let payload = { state: false };
      this.$emit("closePicture", payload);
    },
    submit() {
      let profile_picture = this.myPicture;
      let data = { profile_picture };
      console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitPicture", payload);
    },
  },
};
</script>
