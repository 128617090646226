<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>My Professional Background</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-small text @click.stop="newDialog = true">
        new background
      </v-btn>
      <BackgoundNew
        :newDialog="newDialog"
        @closeNew="closeNew"
        @submitNew="submitNew"
      />
      <v-btn icon @click="refresh()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs v-model="tabs" centered show-arrows>
      <v-tab v-for="i in backgroundList" :key="i.id">
        {{ i.specialization }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="i in backgroundList" :key="i.id">
        <v-row style="margin: 10px 0px">
          <v-col cols="12" sm="6">
            <v-card flat outlined>
              <v-card-text>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">current work</div>
                      <div class="myright">
                        {{ i.current_work }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">experience</div>
                      <div class="myright">
                        {{ i.experience }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">gained from</div>
                      <div class="myright">
                        {{ i.gained_from }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">started</div>
                      <div class="myright">
                        {{ i.started | myDate }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">ended</div>
                      <div class="myright">
                        {{ i.ended | myDate }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">showing</div>
                      <div class="myright">
                        {{ i.showing ? "Yes" : "No" }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat outlined>
              <v-card-text>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">contact</div>
                      <div class="myright">
                        {{ i.contact }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">contact phone</div>
                      <div class="myright">
                        {{ i.contact_phone }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">ontact email</div>
                      <div class="myright smallcaps">
                        {{ i.ontact_email }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">endorsed</div>
                      <div class="myright">
                        {{ i.endorsed ? "Yes" : "No" }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">updated</div>
                      <div class="myright">
                        {{ i.updated | myDating }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">created</div>
                      <div class="myright">
                        {{ i.created | myDating }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-toolbar flat dense>
          <v-btn
            small
            text
            color="primary"
            @click.stop="$set(editDialog, i.id, true)"
          >
            Update {{ i.specialization }}
          </v-btn>
          <BackgroundUpdate
            v-if="editDialog[i.id]"
            :editDialog="editDialog[i.id]"
            :myId="i.id"
            :name="i.specialization"
            @closeEdit="closeEdit"
            @submitEdit="submitEdit"
          />

          <v-spacer></v-spacer>
          <v-btn
            text
            small
            color="red"
            @click.stop="$set(removeDialog, i.id, true)"
            >delete {{ i.specialization }}</v-btn
          >
          <BackgroundRemove
            v-if="removeDialog[i.id]"
            :removeDialog="removeDialog[i.id]"
            :myId="i.id"
            :name="i.specialization"
            @closeRemove="closeRemove"
            @submitRemove="submitRemove"
          />
        </v-toolbar>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import BackgoundNew from "../../components/accounts/background/newone.vue";
import BackgroundUpdate from "../../components/accounts/background/update.vue";
import BackgroundRemove from "../../components/accounts/background/remove.vue";
export default {
  components: { BackgoundNew, BackgroundUpdate, BackgroundRemove },
  data: () => ({
    newDialog: false,
    editDialog: {},
    removeDialog: {},
    tabs: null,
    backgroundList: [],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
  }),
  created() {
    this.list();
  },
  methods: {
    refresh() {
      this.list();
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.background
        .list()
        .then((response) => {
          console.log(response.data);
          this.backgroundList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeNew(p) {
      console.log(p);
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.background
        .create(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.newDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.background
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.background
        .remove(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
