<template>
  <v-dialog v-model="newDialog" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-toolbar dense flat>
        <v-toolbar-title> New Health Blog </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          style="margin: 20px 0 0 0"
          small
          v-model="add.publish"
          :label="`Publish: ${add.publish ? 'now' : 'later'}`"
        ></v-switch>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="padding: 30px 16px 16px">
        <v-text-field v-model="add.title" dense label="title "></v-text-field>

        <vue-editor
          v-model="add.details"
          placeholder="Start writing your Healh Blog here. You can come back later to edit it any time during or before you publish it."
        ></vue-editor>
        <v-text-field
          style="margin-top: 20px"
          v-model="add.tags"
          dense
          label="tags"
          placeholder="Comma separated words that describes this blog"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.add.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import Measurements from "../../mixins/measurements";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  mixins: [Utils, Measurements, validationMixin],
  components: {
    VueEditor,
  },
  props: {
    newDialog: Boolean,
    myId: { trype: Number },
  },
  data() {
    return {
      loading: false,
      add: {
        title: null,
        details: null,
        publish: false,
        tags: null,
      },
    };
  },
  validations: {
    add: {
      title: { required },
      details: { required },
      publish: { required },
    },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let title = this.add.title;
      let details = this.add.details;
      let publish = this.add.publish;

      let data = {
        title,
        details,
        publish,
      };
      console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
