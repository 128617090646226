<template>
  <div>
    <v-toolbar dense flat>
      <v-btn color="primary" x-small text @click.stop="newDialog = true">
        new health blog
      </v-btn>
      <BlogNew
        :newDialog="newDialog"
        @closeNew="closeNew"
        @submitNew="submitNew"
      />

      <v-spacer></v-spacer>
      <v-btn color="primary" icon @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row style="margin-top: 20px">
      <v-col cols="12" sm="3">
        <v-card outlined v-resize="onResize">
          <v-list rounded dense three-line>
            <v-subheader>TITLES</v-subheader>
            <div class="scrollable" :style="{ height: panheight }">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="item in blogList"
                  :key="item.id"
                  @click="selectedBlog(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title> {{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.created | myDate }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Published:
                      <v-icon small>
                        {{ item.publish ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9">
        <v-toolbar flat dense style="padding: 0">
          <v-toolbar-title>{{ selected.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            x-small
            text
            :disabled="enable"
            color="primary"
            @click.stop="$set(editDialog, selected.id, true)"
          >
            edit
          </v-btn>
          <BlogEdit
            v-if="editDialog[selected.id]"
            :editDialog="editDialog[selected.id]"
            :myId="selected.id"
            :myData="selected"
            @closeEdit="closeEdit"
            @submitEdit="submitEdit"
          />

          <v-btn
            x-small
            text
            color="error"
            :disabled="enable"
            @click.stop="$set(removeDialog, selected.id, true)"
          >
            delete
          </v-btn>
          <BlogRemove
            v-if="removeDialog[selected.id]"
            :removeDialog="removeDialog[selected.id]"
            :myId="selected.id"
            :myData="selected"
            @closeRemove="closeRemove"
            @submitRemove="submitRemove"
          />
        </v-toolbar>
        <v-divider></v-divider>
        <v-card flat v-resize="onResize">
          <v-card-text style="padding: 0">
            <div class="scrollable" :style="{ height: panheight }">
              <div class="edited_template" v-html="selected.details"></div>

              <div style="margin: 16px 0 0 0; font-size: 0.9em">
                <v-card outlined>
                  <v-card-text>
                    <div style="font-size: 0.7em">TAGS</div>
                    {{ selected.tags }}
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";

import BlogNew from "../../components/blogs/newone.vue";
import BlogEdit from "../../components/blogs/edit.vue";
import BlogRemove from "../../components/blogs/delete.vue";

export default {
  mixins: [Utils],
  components: { BlogNew, BlogEdit, BlogRemove },
  data: () => ({
    newDialog: false,
    editDialog: {},
    removeDialog: {},
    blogList: [],
    selected: {},
    enable: true,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ...",
    },
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 380 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.blogs
        .userlist()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.blogList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    selectedBlog(selected) {
      this.selected = selected;
      console.log(selected);
      this.enable = false;
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .create(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.newDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      console.log("p:", p);
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .delete(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
