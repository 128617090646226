<template>
  <v-dialog v-model="updateDialog" max-width="400" persistent>
    <v-card>
      <v-card-title>update {{ name }}</v-card-title>
      <v-card-text>
        <v-select
            v-model="view.specializationid"
            :items="specialtyList"
            dense
            item-text="name"
            item-value="id"
            label="specialization"
        ></v-select>
        <v-select
            v-model="view.specialization_detailsid"
            :items="specialDetList"
            dense
            item-text="name"
            item-value="id"
            label="specialization detail"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    updateDialog: Boolean,
    myId: {type: Number, default: 0},
    name: {type: String, default: null},
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      specialtyList: [],
      specialDetList: [],
      view: {
        specializationid: null,
        specialization_detailsid: null,
      },
    };
  },
  created() {
    this.find();
    this.specialty();
  },
  watch: {
    "view.specializationid": function (v) {
      console.log(v);
      if (v > 0) {
        this.specialDetail(v);
      }
    },
  },
  methods: {
    specialty() {
      let self = this;
      Restful.general.specialty
          .list()
          .then((response) => {
            console.log(response.data);
            this.specialtyList = response.data;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    specialDetail(i) {
      let self = this;
      Restful.general.specialtyDetails
          .list(i)
          .then((response) => {
            console.log(response.data);
            this.specialDetList = response.data;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    find() {
      let self = this;
      Restful.accounts.mySubs
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.view.specializationid = response.data.specializationid;
            this.view.specialization_detailsid =
                response.data.specialization_detailsid;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeUpdate", payload);
    },
    submit() {
      let payload = {id: this.myId, state: false, data: this.view};
      this.$emit("submitUpdate", payload);
    },
  },
};
</script>
